import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Header/Navbar";
import Footer from "../../../components/footer";
import BottomHeader from "../../../components/Header/BottomHeader";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import { useParams } from "react-router-dom";
import moment from "moment";

const OrderDetails = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_url}/order/get/${id}`)
      .then((res) => {
        setOrderDetails(res?.data?.data);
        setProducts(res?.data?.data?.cart || []);
      })
      .catch((error) => console.error(error));
  }, [id]);

  const totalPrice = products.reduce(
    (acc, product) => acc + product?.totalOriginalPrice * product?.quantity,
    0
  );

  return (
    <>
      <Navbar />
      <BottomHeader />
      <div className="max-w-5xl mx-auto py-10 px-6">
        <h1 className="text-3xl font-bold text-gray-900 text-center mb-8">
          Your Order Details
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Order Info */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-bold text-gray-800 border-b pb-4 mb-6">
              Order Info
            </h2>
            <div className="space-y-4 text-sm text-gray-700">
              <p><strong>Order Date:</strong> {moment(orderDetails?.updatedAt).format('DD-MM-YYYY') || "N/A"}</p>
              <p><strong>Delivery Date:</strong> {orderDetails?.toDeliverDay}</p>
              <p><strong>Status:</strong> {orderDetails?.status || "N/A"}</p>
              <p><strong>Payment Status: </strong>{orderDetails?.paymentStatus}</p>
              <p><strong>Payment Method: </strong>{orderDetails?.accountType}</p>
             
            </div>
          </div>

          {/* Customer Info */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-bold text-gray-800 border-b pb-4 mb-6">
              Customer
            </h2>
            <div className="space-y-4 text-sm text-gray-700">
              <p><strong>Name:</strong> {orderDetails?.firstName} {orderDetails?.lastName}</p>
              {/* <p><strong>Email:</strong> {orderDetails.email}</p> */}
              <p><strong>Phone:</strong> {orderDetails?.phone}</p>
            </div>
          </div>

          {/* Address Info */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-bold text-gray-800 border-b pb-4 mb-6">
              Address
            </h2>
            <div className=" text-sm text-gray-700">
            <p  className=""><strong>Name:</strong> {orderDetails?.firstName} {orderDetails?.lastName}</p>
              <p><strong>Shipping Address:</strong> {orderDetails?.addressLine1}, {orderDetails?.city}, {orderDetails?.state}, {orderDetails?.country}</p>
              <p><strong>Comment:</strong> {orderDetails?.comment}</p>
            </div>
          </div>
        </div>

        {/* Product Details */}
        <div className="mt-10 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-bold text-gray-800 border-b pb-4 mb-6">
            Products
          </h2>
          {products?.length > 0 ? (
            products?.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between border-b pb-4 mb-4 hover:bg-gray-50 p-4 rounded-md transition"
              >
                <img
                  src={item?.product?.Image1}
                  alt={item?.product?.name}
                  className="w-20 h-20 object-cover rounded-lg border"
                />
                <div className="flex-1 mx-4">
                  <h3 className="text-lg font-medium text-gray-800">
                    {item?.product?.name}
                  </h3>
                  <p className="text-sm text-gray-600">Quantity: {item?.quantity}</p>
                </div>
                <p className="text-lg font-bold text-gray-900">
                  ${(item?.totalOriginalPrice)}AUD
                </p>
              </div>
            ))
          ) : (
            <p className="text-gray-600">No products found in this order.</p>
          )}
        </div>

        {/* Order Summary */}
        <div className="mt-10 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-bold text-gray-800 border-b pb-4 mb-6">
            Order Summary
          </h2>
          <div className="space-y-4 text-sm text-gray-700">
            <div className="flex justify-between">
              <span>Subtotal</span>
              <span>${orderDetails?.subTotalBill}AUD</span>
            </div>
            <div className="flex justify-between">
              <span>Shipping Charge</span>
              <span>${orderDetails?.deliveryCharges}AUD</span>
            </div>
            
            <div className="flex justify-between">
              <span>Discount</span>
              <span>{(Number(orderDetails?.subTotalBill)*Number(orderDetails?.discount))/100}</span>
            </div>
            <div className="flex justify-between text-lg font-bold text-gray-900">
              <span>Total</span>
              <span>${orderDetails?.totalBill}AUD</span>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OrderDetails;
